import {
  citiesPickerProps,
  companyTypesPickerProps,
  countriesPickerProps,
  fundingStagesPickerProps,
  industriesPickerProps,
  MultiSelect,
  MenuSelectProps,
  MultiSelectState,
  operatingStatusesPickerProps,
  ratingsPickerProps,
  statesPickerProps,
  revenueStreamProps,
  salesTargetPickerProps,
  subRegionProps,
  regionProps,
  useIndustryPickerProps,
  SelectedIndustryGroups,
  pipelineStatusProps,
} from '../../stories/Filtering/Filters/MultiSelect'
import { DebouncedInputGroupProps, DebouncedInputGroupState, DebounchedInputGroup } from './DebounchedInputGroup'
import { NumberRangePicker, NumberRangePickerProps, NumberRangePickerState } from '../../stories/Filtering/Filters/NumberRangePicker'
import React, { ReactElement } from 'react'
import { SearchLogicKey, SearchLogicValue } from '../../stories/Filtering/SearchLogicDropdown'
import { OmniFilterState } from '../../stories/Filtering/OmniFilter'
import { Column, FilterId } from '../../logic/AgGrid/Column'

export class Filter {
  label: Column['label']

  constructor(public id: FilterId, public type: SearchLogicKey, public fc?: FilterFC) {
    this.label = Column.withId(id).label
  }
}

export type FilterState = MultiSelectState | DebouncedInputGroupState | NumberRangePickerState
export type FilterFC = (state: FilterState | undefined, onChange: (state: FilterState) => void, type: SearchLogicValue) => ReactElement

//oc = "On Change",  s = "state"
export const allFilters = {
  review: [
    new Filter('ml_rating', 'contains', (s, oc) => <MultiSelect {...ratingsPickerProps(true)} onChange={oc} {...s} />),
    new Filter('rating', 'contains', (s, oc) => <MultiSelect {...ratingsPickerProps(false)} onChange={oc} {...s} />),
    new Filter('list_ids', 'set', (s, oc) => <MultiSelect onChange={oc} {...(s as MenuSelectProps)} />),
    new Filter('pipeline_cards', 'contains', (s, oc) => <MultiSelect {...pipelineStatusProps()} onChange={oc} {...s} />),
  ],
  basics: [
    new Filter('combined_keyword', 'text', (s, oc) => <DebounchedInputGroup {...combinedKeywordProps} onChange_={oc} {...s} />),
    new Filter('name', 'text', (s, oc) => <DebounchedInputGroup {...debounchedInputProps} onChange_={oc} {...s} />),
    new Filter('domain', 'text', (s, oc) => <DebounchedInputGroup {...debounchedInputProps} onChange_={oc} {...s} />),
    new Filter('short_description', 'text', (s, oc) => <DebounchedInputGroup {...debounchedInputProps} onChange_={oc} {...s} />),
    new Filter('description', 'text', (s, oc) => <DebounchedInputGroup {...debounchedInputProps} onChange_={oc} {...s} />),
    new Filter('founded_on_date', 'numberRange', (s, oc) => <NumberRangePicker {...yearRangeProps} onChange={oc} {...s} />),
    new Filter('country_iso_3166_1_alpha_3', 'contains', (s, oc) => <MultiSelect {...countriesPickerProps} onChange={oc} {...s} />),
    new Filter('state', 'contains', (s, oc) => <MultiSelect {...statesPickerProps} onChange={oc} {...s} />),
    new Filter('city', 'contains', (s, oc) => <MultiSelect {...citiesPickerProps} onChange={oc} {...s} />),
    // new Filter('industries', 'set', (s, oc) => <MultiSelect {...industriesPickerProps} onChange={oc} {...s} />),
    new Filter('status_enum', 'contains', (s, oc) => <MultiSelect {...operatingStatusesPickerProps} onChange={oc} {...s} />),
    new Filter('li_type_enum', 'contains', (s, oc) => <MultiSelect {...companyTypesPickerProps} onChange={oc} {...s} />),
    new Filter('employee_count_int4range', 'numberRange', (s, oc) => <NumberRangePicker {...bigNumberProps} onChange={oc} {...s} />),
  ],
  'Kurrant.aiInsights': [
    new Filter('sales_target_type', 'set', (s, oc) => <MultiSelect onChange={oc} {...salesTargetPickerProps} {...s} />),
    new Filter('revenue_stream_type', 'set', (s, oc) => <MultiSelect onChange={oc} {...revenueStreamProps} {...s} />),
    new Filter('industries', 'set', (s, oc) => <MultiSelect {...useIndustryPickerProps()} onChange={oc} {...s} />),
    new Filter('sub_region', 'set', (s, oc) => <MultiSelect onChange={oc} {...subRegionProps} {...s} />),
    new Filter('region', 'set', (s, oc) => <MultiSelect onChange={oc} {...regionProps} {...s} />),
  ],
  growth: [
    new Filter('li_employee_count_integer', 'numberRange', (s, oc) => <NumberRangePicker {...bigNumberProps} onChange={oc} {...s} />),
    new Filter('li_employee_count_mom_decimal', 'numberRange', (s, oc) => <NumberRangePicker {...growthPercentageProps} onChange={oc} {...s} />),
    new Filter('bw_revenue_usd_bigint', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
    new Filter('bw_revenue_mom_decimal', 'numberRange', (s, oc) => <NumberRangePicker {...growthPercentageProps} onChange={oc} {...s} />),
    new Filter('tech_spent_bigint_usd', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
    new Filter('ig_followers_count_integer', 'numberRange', (s, oc) => <NumberRangePicker {...bigNumberProps} onChange={oc} {...s} />),
    new Filter('ig_followers_count_mom_decimal', 'numberRange', (s, oc) => <NumberRangePicker {...growthPercentageProps} onChange={oc} {...s} />),
    new Filter('li_followers_count_integer', 'numberRange', (s, oc) => <NumberRangePicker {...bigNumberProps} onChange={oc} {...s} />),
    new Filter('li_followers_count_mom_decimal', 'numberRange', (s, oc) => <NumberRangePicker {...growthPercentageProps} onChange={oc} {...s} />),
  ],
  financials: [
    new Filter('total_assets_bigint_usd', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
    new Filter('revenue_bigint_usd', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
    new Filter('net_income_bigint_usd', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
    new Filter('operating_income_bigint_usd', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
  ],
  funding: [
    new Filter('total_funding_usd_bigint', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
    new Filter('investors', 'text', (s, oc) => <DebounchedInputGroup placeholder={'Search…'} fill={true} onChange_={oc} {...s} />),
    new Filter('latest_funding_amount_bigint', 'numberRange', (s, oc) => <NumberRangePicker {...bigMoneyProps} onChange={oc} {...s} />),
    new Filter('latest_funding_on_date', 'numberRange', (s, oc) => <NumberRangePicker {...yearRangeProps} onChange={oc} {...s} />),
    new Filter('latest_funding_type', 'set', (s, oc) => <MultiSelect {...fundingStagesPickerProps} onChange={oc} {...s} />),
    new Filter('funding_round_count_integer', 'numberRange', (s, oc) => <NumberRangePicker {...oneTo100RangeProps} onChange={oc} {...s} />),
    new Filter('exit_count_integer', 'numberRange', (s, oc) => <NumberRangePicker {...oneTo100RangeProps} onChange={oc} {...s} />),
  ],
  socialMedia: [
    new Filter('linkedin_url', 'socialMedia'),
    new Filter('facebook_url', 'socialMedia'),
    new Filter('twitter_url', 'socialMedia'),
    new Filter('ig_username', 'socialMedia'),
    new Filter('pinterest_url', 'socialMedia'),
  ],
}

export type FilterMap = {
  [Property in FilterId]+?: Filter
}

export const allFiltersMap: FilterMap = Object.fromEntries(
  Object.values(allFilters)
    .flat()
    .map((f) => [f.id, f])
)

export type FilterModel = FilteredColumn[]

export type FilteredColumn = {
  column: FilterId
  filterType: SearchLogicKey
  searchLogic: SearchLogicValue
  state: FilterState
  showNoData?: boolean
}

export class FilterModelUtils {
  static serialize(state: OmniFilterState[]): FilterModel {
    const filterModel: FilterModel = []
    state.forEach((fs) => {
      if (fs.filter && fs.searchLogic) {
        filterModel.push({
          column: fs.filter.id,
          filterType: fs.filter.type,
          searchLogic: fs.searchLogic,
          state: fs.filterState ?? {},
          ...(fs.showNoData !== undefined && { showNoData: fs.showNoData }),
        })
      }
    })
    return filterModel
  }

  static deserialize(filterModel?: FilterModel): OmniFilterState[] {
    if (filterModel) {
      return filterModel.map(({ column, searchLogic, state, showNoData }) => ({
        filter: allFiltersMap[column],
        searchLogic,
        filterState: state,
        showNoData,
      }))
    }
    return []
  }
}

export const debounchedInputProps: DebouncedInputGroupProps = {
  placeholder: 'Search…',
  fill: true,
}

export const combinedKeywordProps: DebouncedInputGroupProps = {
  placeholder: 'Search Name, Domain, Description…',
  fill: true,
}

const currentYear = new Date().getFullYear()
export const yearRangeProps: NumberRangePickerProps = {
  min: 0,
  max: currentYear,
  placeholders: ['1900', currentYear.toFixed()],
  formatting: 'raw',
}

export const growthPercentageProps: NumberRangePickerProps = {
  min: -50,
  max: 50,
  placeholders: ['-50/0/50', '-50/0/50'],
  leftIcon: 'percentage',
}

export const bigNumberProps: NumberRangePickerProps = {
  min: 0,
  max: 100_000_000,
  placeholders: ['1000/1K', '1000000/1M'],
  formatting: 'suffixes',
}

export const bigMoneyProps: NumberRangePickerProps = {
  min: 0,
  max: 10_000_000_000_000,
  placeholders: ['1000/1K', '10000000/10M'],
  leftIcon: 'dollar',
  formatting: 'suffixes',
}

export const oneTo100RangeProps: NumberRangePickerProps = {
  min: 0,
  max: 100,
}
