import { Indexed } from './Backend'
import { useLocation } from 'react-router'
import { useMemo } from 'react'

export interface Route {
  href: string
  text?: string
}

export const lists: Route = { href: '/lists', text: 'Lists' }
export const list: Route = { href: '/lists/:id' }
export const companies: Route = { href: '/companies', text: 'All Companies' }
export const company: Route = { href: '/companies/:id' }
export const preferences: Route = { href: '/account', text: 'My Account' }
export const login: Route = { href: '/login', text: 'Log In' }
export const signup: Route = { href: '/signup', text: 'Sign Up' }
export const passwordReset: Route = { href: '/passwordReset', text: 'Reset Password' }
export const gpt: Route = { href: 'https://analysis.kurrant.ai/', text: 'KurrantGPT' }

export const hrefForId: (route: Route, id: Indexed['id']) => string = (route, id) => {
  return route.href.replace(':id', id.toString())
}

export function useQueryParams<Params extends string = string>(params: Params[]): Record<Params, string | undefined> {
  const { search } = useLocation()
  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(search)
    return Object.fromEntries(params.map((p) => [p, urlSearchParams.get(p) ?? undefined])) as Record<Params, string | undefined>
  }, [search])
}
