// pipelineDropDown.tsx
import React, { useEffect, useRef, useState } from 'react'
import '../styles/pipelineDropDown.scss'
import { Icon } from '@blueprintjs/core'
import { STATUS_OPTIONS } from './CompanyStatusDropDown'
import { updatePipelineData } from '../../../../logic/Backend'

type StatusOption = {
  value: string
  index: number
  label: string
  backgroundColor: string
  color: string
  borderColor: string
}

type PipelineDropDownProps = {
  organization_id: string
  pipeline_status: number
}

const PipelineDropDown: React.FC<PipelineDropDownProps> = ({ organization_id, pipeline_status = 6 }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [selectedStatus, setSelectedStatus] = useState<StatusOption>(STATUS_OPTIONS[pipeline_status])
  useEffect(() => {
    setSelectedStatus(STATUS_OPTIONS[pipeline_status])
  }, [pipeline_status])
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleStatusChange = async (option: StatusOption) => {
    setIsOpen(false)
    setSelectedStatus(option)

    try {
      if (option.value === 'no_status') {
        await updatePipelineData({ organization_ids: [organization_id], pipeline_status: null })
      } else {
        await updatePipelineData({ organization_ids: [organization_id], pipeline_status: option.value })
      }
    } catch (error) {
      console.error('Failed to update card status:', error)
    }
  }

  return (
    <div
      className="pipeline-dropdown"
      ref={dropdownRef}
      style={{
        borderColor: selectedStatus.borderColor,
      }}
    >
      <div
        className="dropdown-header"
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: selectedStatus.backgroundColor, color: selectedStatus.color, borderColor: selectedStatus.borderColor }}
      >
        <div className="selected-option">
          <span className="label">{selectedStatus.label}</span>
          <span className="dropdown-arrow">{isOpen ? <Icon icon="chevron-up" iconSize={14} /> : <Icon icon="chevron-down" iconSize={14} />}</span>
        </div>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {STATUS_OPTIONS.map((option) => (
            <div
              key={option.value}
              className={`dropdown-item ${selectedStatus.value === option.value ? 'selected' : ''}`}
              onClick={() => handleStatusChange(option)}
              style={{
                backgroundColor: option.backgroundColor,
                color: option.color,
                borderColor: option.borderColor,
              }}
            >
              <span className="label">{option.label}</span>
              <span className="checkmark">{selectedStatus.value === option.value && <Icon icon="tick" iconSize={14} />}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default PipelineDropDown
