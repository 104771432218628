import React, { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styles from '../ui/app/App.module.scss'
import { Icon, IconName, MaybeElement } from '@blueprintjs/core'
import { Tooltip } from './Tooltip'

export interface INavLinkButtonProps extends Partial<NavLinkProps> {
  label: string
  path: string
  icon: IconName | MaybeElement
  isCollapsed: boolean
  externalTarget?: string
}

export const NavLinkButton: FC<INavLinkButtonProps> = ({ label = '', path = '', icon = 'Blank', isActive, isCollapsed, externalTarget = '' }) => {
  const iconName = icon as IconName
  return (
    <NavLink
      to={{ pathname: path }}
      className={(isActive) => (isActive ? styles.navlinkIsActive : styles.navlink)}
      activeClassName={''}
      isActive={isActive}
      target={externalTarget}
    >
      <Tooltip position={'right'} openOnTargetFocus={false} disabled={!isCollapsed}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '13px',
            paddingLeft: '6px',
            paddingRight: '6px',
            paddingTop: '7.5px',
            paddingBottom: '7.5px',
          }}
        >
          {typeof iconName === 'string' ? <Icon icon={iconName} iconSize={20} /> : iconName}
          {!isCollapsed && <div>{label}</div>}
        </div>
        <div>{label}</div>
      </Tooltip>
    </NavLink>
  )
}
